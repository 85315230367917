import * as IS from "./validator";

export default function Confirm(text) {

    let handleResponse;
    let moreText = [];

    if (arguments.length > 1) {
        for (let i=1; i<arguments.length; i++) {
            if (IS.string(arguments[i])) moreText.push(arguments[i]); 
            else if (IS.func(arguments[i])) handleResponse = arguments[i];
        }
    }

    function handleKeyDown(event) {
        if (event.key === "Enter") {
            handleResponse(true); 
            closeConfirm();
        }
        if (event.key === "Escape") {
            handleResponse(false);
            closeConfirm();
        }
    }
    function closeConfirm() {
        document.getElementById("confirmContainer").remove(); 
        document.removeEventListener("keydown", handleKeyDown);
    }
    
    const span = document.createElement("span");
    const h3 = document.createElement("h3");
    h3.innerHTML = text;
    span.appendChild(h3);

    if (moreText.length) {
        const p = document.createElement("p");
        p.innerHTML = moreText.join("<br>");
        span.appendChild(p);
    }

    const buttonYes = document.createElement("button");
    buttonYes.innerHTML = "SI";
    buttonYes.onclick = () => { handleResponse(true); closeConfirm(); };
    const buttonNo = document.createElement("button");
    buttonNo.innerHTML = "NO";
    buttonNo.onclick = () => { handleResponse(false); closeConfirm() };

    const line = document.createElement("line");    
    line.appendChild(buttonYes);
    line.appendChild(buttonNo);

    const confirmBox = document.createElement("div");
    confirmBox.className = "confirm";
    confirmBox.appendChild(span);
    confirmBox.appendChild(line);

    const confirmContainer = document.createElement("div");
    confirmContainer.id = "confirmContainer";
    confirmContainer.className = "confirmContainer";
    confirmContainer.appendChild(confirmBox);    

    const parentElement = document.getElementById("root");
    if (parentElement) parentElement.appendChild(confirmContainer);

    document.addEventListener("keydown", handleKeyDown);
}


