import React, { Suspense } from "react";

import Confirm from "./elements/confirm";
import Popup from "./elements/popup";
import { Button, Loading } from "./elements/fields";
import { goUrl, request } from "./elements/functions";
import * as IS from "./elements/validator";

import "./styles/main.css";

const initState = {
    activeFile: "",
    menuItems: []
}

class App extends React.Component {
    state = {
        ...initState,
        loading: false
    }

    componentDidMount() {
        this.loadItems(); 
    }

    loadItems = async (el) => {
        try {
            this.setState({ loading: true, ...initState });
            const r = await request("login/getMenu");
            if (r.statusCode === 200) this.setState({ menuItems: r.body, activeFile: r.body[0].file });
            else Popup(r);
            if (IS.object(el)) this.setState({ ...el });
            this.setState({ loading: false });                
        } catch(e) {
            Popup(e);
            this.setState({ loading: false });
        }        
    }

    clickLogout = () => {
        Confirm(`Sicuro di voler eseguire Logout ?`, (confirm) => {
            if (confirm) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                localStorage.removeItem("role");
                goUrl("login");
            }
        })
    }

    clickRefresh = () => {
        Confirm(`Ricaricare la Pagina ?`, (confirm) => {
            if (confirm) goUrl("");
        })
    }

    clickHelp = () => {
        try {
            const role = localStorage.getItem("role");
            Popup(`<h1>ASSISTENZA</h1>`, 
                `<h3>Visualizza la guida <a href="/metas/docs/guida_${role}.pdf" target="_blank">QUI</a></h3>`,
                `<h3>Oppure contatta il Supporto <a href="mailto:areariservata@cemambiente.it">QUI</a></h3>`,599
            )
        } catch(e) {
            Popup(e);
        }
    }

    render() {
        const { activeFile, menuItems, loading } = this.state;

        const token = localStorage.getItem("token");
        if (!token) goUrl("login");

        if (loading) return (<Loading className="elementContainer" />);
        else {
            let fl = "nofile";
            if (IS.string(activeFile)) fl = activeFile;
            const ContentComponent = React.lazy(() => import(`./components/${fl}`));            
            return (     
                <React.Fragment>
                    <img src="/img/logo.png" alt="logo" className="logoImg" />
                    <div className="menu">
                        <div className="sliderShow">
                            {IS.array(menuItems, true) && (
                                <React.Fragment>
                                    {menuItems.map((el) => (
                                        <Button 
                                            key={el._id}
                                            image={el.img} 
                                            hover={IS.string(el.hover) ? el.hover : el.name} 
                                            onClick={() => {
                                                if (el.file !== activeFile) this.setState({ activeFile: el.file });
                                            }} 
                                            selected={el.file === activeFile}
                                            divStyle={{ display: "inline-flex" }}
                                        />
                                    ))}
                                </React.Fragment>
                            )}
                            <Button 
                                image="/img/refresh.png" 
                                onClick={this.clickRefresh} 
                                hover="RICARICA" 
                                divStyle={{ display: "inline-flex" }} 
                            /> 
                            <Button 
                                image="/img/help.png" 
                                onClick={this.clickHelp} 
                                hover="ASSISTENZA" 
                                divStyle={{ display: "inline-flex" }} 
                            /> 
                            <Button 
                                image="/img/logout.png" 
                                onClick={this.clickLogout} 
                                hover="LOGOUT" 
                                divStyle={{ display: "inline-flex" }} 
                            /> 
                        </div>
                    </div>
                    <Suspense fallback={<h1>... LOADING COMPONENT ...</h1>}>
                        <ContentComponent />
                    </Suspense> 
                </React.Fragment>     
            )   
        }            
    }
}

export default App;
