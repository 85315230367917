import * as IS from "./validator";
import Popup from "./popup";
import { HoverDiv } from "./fields";
/**
 * Execute an HTTPS REQUEST to backend, accepted params are:
 * - String "noAuth" if given perform request without token login
 * - String ("POST", "GET", "PUT", "DELETE") set the request method, default POST
 * - String ("json", "blob", "text") set the return value type, default json
 * - Object define the body of request
 * - Object.headers define the headers
 * @param {string} api - API path 
 *  
 */
export function request(api) {
    return new Promise(async (resolve, reject) => {
        try {
            let auth = true;
            let method = "json";
            let options = {
                method: "POST",
                body: {}
            }
            if (arguments.length > 1) {
                for (let i=1; i<arguments.length; i++) {
                    if (IS.formData(arguments[i])) options.body = arguments[i];
                    else if (IS.object(arguments[i])) options.body = arguments[i];
                    else if (IS.string(arguments[i]) && arguments[i] === "noAuth") auth = false;
                    else if (IS.string(arguments[i]) && ["json", "blob", "text"].includes(arguments[i])) method = arguments[i];
                    else if (IS.string(arguments[i]) && ["POST", "GET", "PUT", "DELETE"].includes(arguments[i])) options.method = arguments[i];
                }
            }
            if (options.method === "GET") delete options.body;
            else if (!IS.formData(options.body) && IS.object(options.body)) {
                options.body = JSON.stringify(options.body);
                options.headers = { "Content-Type" : "application/json" };
            } 
            if (auth) {
                const token = localStorage.getItem("token");
                if (token) {
                    if (!IS.object(options.headers)) options.headers = {};
                    options.headers["Authorization"] = `Bearer ${token}`;
                }
            }
            const { protocol, hostname, port } = window.location;
            let url = `${protocol}//${hostname}:${port}/`;
            if (isValidUrl(api)) url = api;
            else if (IS.string(api) && api[0] === "/") url += api.substring(1);
            else url += api;
            const r = await fetch(url, options); 
            if (auth && r.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                localStorage.removeItem("role");
                goUrl("login");
            } else if (r.headers.get("Content-Type") && r.headers.get("Content-Type").includes("text/html")) {
                try {
                    const result = await r.text();                   
                    resolve({ statusCode: r.status, html: result }); 
                } catch(e) {
                    reject(new Error(e.message));
                } 
            } else if (r.headers.get("Content-Type") && r.headers.get("Content-Type").includes("text/plain")) {
                try {
                    const result = await r.text();                   
                    resolve({ statusCode: r.status, result: result }); 
                } catch(e) {
                    reject(new Error(e.message));
                } 
            } else if (r.ok && method === "blob") {
                try {
                    const result = await r.blob();                        
                    const objectURL = URL.createObjectURL(result);
                    resolve({ statusCode: r.status, objectURL: objectURL }); 
                } catch(e) {
                    reject(new Error(e.message));
                } 
            } else if (method === "text") {
                try {
                    const result = await r.text();                   
                    resolve({ statusCode: r.status, result: result }); 
                } catch(e) {
                    reject(new Error(e.message));
                }  
            } else if (method === "json") {
                try {
                    let result = await r.json();
                    result.statusCode = r.status;
                    resolve(result); 
                } catch(e) {
                    reject(new Error(e.message));
                }                             
            } else {
                resolve({ statusCode: r.status, result: r.statusText });
            }                 
        } catch(e) {
            reject(new Error(e.message));
        }
    })    
}

export const bounce = (event) => {
    const { target } = event;
    target.classList.add("bounceButton");
    target.addEventListener("animationend", () => { target.classList.remove("bounceButton") });
}
/**
 * 
 * @param {string} url - If complete url go there, if void same page, else backend + url
 * @param {boolean} [blank] - True to Open new window 
 */
export const goUrl = (url, blank) => {
    try {
        const { protocol, hostname, port, pathname } = window.location;
        let dest = `${protocol}//${hostname}:${port}`;
        if (url && isValidUrl(url)) dest = url;
        else if (url && (typeof url === "string")) {
            if (url[0] === "/") dest = dest + url;
            else dest = `${dest}/${url}`;
        } else dest = dest + pathname;        
        if (blank) window.open(dest, "_blank");
        else window.location.href = dest;
    } catch(e) {
        console.log(e);
    }
}

export function validateIP(ipaddress) {  
    if (!ipaddress || (typeof ipaddress !== "string")) return(false);
    else if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) 
        return(true);  
    else if (ipaddress.includes(".") && (ipaddress.split(".").length >= 3)) return(true);
    else return (false)  
}  


export function sleep(ms) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, ms);
    })
}

export function validateEmail(email) {
    try {
        const splitted = email.split("@");
        if (splitted.length === 2) {
            if (splitted[1].includes(".")) return (true);
            else return (false);
        } else return (false);
    } catch(err) {
        return (false);
    }
}

export function isValidUrl(string) {
    try { 
        return Boolean(new URL(string)); 
    } catch(e){ 
        return false; 
    }
}
/**
 * 
 * @param {string} url - If complete url go there, if void same page, else backend + url
 * @returns {string} - The URL string
 */
export function createURL(url, params) {
    try {
        const { protocol, hostname, port, pathname } = window.location;
        let dest = `${protocol}//${hostname}:${port}`;
        if (IS.url(url)) dest = url;
        else if (IS.string(url)) {
            if (url[0] === "/") dest += url;
            else dest += `/${url}`;
        } else dest += pathname;
        if (IS.object(params) && Object.keys(params).length) {
            dest += "?";
            for (const [k, v] of Object.entries(params)) {
                dest += `${k}=${v}&`;
            }
            dest = dest.slice(0, -1);
        }
        return dest;     
    } catch(e) {
        return null;
    }
}

export function renderDate(el) {
    try {
        if (IS.date(el)) return new Date(el).toLocaleString();
        else if (el instanceof Date && !isNaN(el)) return el.toLocaleString();
        else if (IS.string(el)) return el;
        else return `- Invalid Date -`;
    } catch(e) {
        return e.message;
    }
}

export function printSize(num, decimals) {
    try {
        const dims = ["B", "KB", "MB", "GB"];
        if (IS.number(num)) {
            let ind = 0;
            let size = num;
            for (let i=0; i<dims.length; i++) {                
                ind = i;                
                if (size > 1000) size = size / 1000;
                else break;
            }
            return `${IS.number(decimals) ? size.toFixed(decimals) : size} ${dims[ind]}`;
        } else if (IS.string(num, true)) r 
        else return `-- Invalid Number ---`;
    } catch(e) {
        return e.message;
    }
}

const specialFields = ["text", "fullScreen", "hover"];
export function createStyle(obj, schema) {
    try {
        if (IS.object(obj) && IS.object(schema)) {
            let style = {};
            for (const [key, val] of Object.entries(obj).filter(([k, v]) => !specialFields.includes(k))) {
                try {
                    if (IS.object(schema[key]) && IS.string(schema[key].add)) style[key] = `${val}${schema[key].add}`;
                    else style[key] = val;
                } catch(e) {
                    console.log(e);
                }
            }
            return style;
        } else return {};
    } catch(e) {
        console.log(e);
        return { color: "red" };
    }
}

export function clickDownload(el) {
    try {
        let h;
        let name;
        let ext;
        if (arguments.length > 1) {
            for (let i=1; i<arguments.length; i++) {
                if (IS.string(arguments[i]) && arguments[i][0] === ".") ext = arguments[i];
                else if (IS.string(arguments[i])) name = arguments[i];
            }
        }
        if (IS.objectUrl(el)) h = el;
        else if (IS.object(el) || IS.array(el)) {
            const f = new Blob([JSON.stringify(el, null, 2)], { type: "application/json" });
            h = URL.createObjectURL(f);
            if (!IS.string(ext)) ext = ".json";
            if (!IS.string(name) && IS.objectId(el._id)) name = el._id;
            else if (!IS.string(name)) name = "document"; 
        }
        if (IS.string(h)) {
            let dw = "";
            if (IS.string(name) && IS.string(ext)) dw = name + ext;
            const a = document.createElement("a");
            a.setAttribute("href", h);
            a.setAttribute("download", dw);
            a.style.display = "none";     
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            HoverDiv(`FILE DOWNLOADED`);
        } else HoverDiv(`Cannot Create URL`); 
    } catch(e) {
        Popup(e);
    }
}
/**
 * Function that return File from ObjectURL
 * @param {string} url 
 * @returns 
 */
export function fileFromURL(url) {
    return new Promise(async (resolve, reject) => {
        try {
            if (!IS.string(url)) reject(new Error(`param url must be a string`));
            else {
                const r = await fetch(url);
                if (r.ok) {
                    const file = await r.blob();
                    resolve(file); 
                } else reject(new Error(r.text()));
            }
        } catch(e) {
            reject(new Error(e.message));
        }
    })
}

export function printPath(el) {
    if (IS.string(el) && el.includes(".")) return el.split(".").filter(e => IS.string(e)).join("/"); 
    else if (IS.string(el)) return el;
    else return "/"; 
}